<template>
  <el-main>
    <el-tabs v-model="activeTab">
      <el-tab-pane v-for="(item, index) in tabsList" :key="index" :label="item.name" :name="item.value"></el-tab-pane>
    </el-tabs>
    <el-form :model="ruleForm" :rules="rules" status-icon ref="ruleForm" label-width="120px" class="demo-ruleForm">
      <div v-show="activeTab === 'one'">
        <el-form-item label="卡项名称：" prop="card_item_name">
          <el-input v-model="ruleForm.card_item_name" placeholder="请输入卡项名称"></el-input>
        </el-form-item>
        <el-form-item label="排序：">
          <el-input class="mr20" v-model="ruleForm.sort" placeholder="请输入内容"></el-input>
          <span class="tips">数字越大，排名越靠前；如为空，排序默认为添加时间越近越靠前</span>
        </el-form-item>
        <el-form-item label="卡项图片：" class="item" prop="card_item_picture">
          <ReadyUploadSource :path="ruleForm.card_item_picture"
            @getSource="data => (ruleForm.card_item_picture = data.path)"
            @removeThis="() => (ruleForm.card_item_picture = '')" :maxSize="1024 * 1024"></ReadyUploadSource>
          <p class="tips">建议图片尺寸800*800px，大小不超过1M</p>
        </el-form-item>
        <el-form-item label="购卡权益：" prop="serviceItem">
          <div>
            <div>
              <el-button size="small" class="mr20" style="color: #409eff" @click="startChooseService">+ 选择服务</el-button>
              <span class="tips">设置此卡支持的服务和服务可用的次数</span>
            </div>
            <el-table class="serviceItem" size="small" :data="ruleForm.serviceItem"
              :header-cell-style="{ 'background-color': '#F8F9FA' }">
              <el-table-column prop="service_name" label="服务信息" align="center"></el-table-column>
              <el-table-column label="售价" align="center">
                <template v-slot="{ row }">￥{{ row.service_price }}</template>
              </el-table-column>
              <el-table-column label="可用次数" align="center">
                <template v-slot="{ row }">
                  <el-input-number size="mini" :min="1" v-model="row.usable_sum"></el-input-number>
                </template>
              </el-table-column>
              <el-table-column label="售价" align="center">
                <template>
                  <el-button type="text" @click="ruleForm.serviceItem = []">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-form-item>
        <el-form-item label="卡项售价：" prop="card_item_price">
          <el-input type="number" placeholder="请输入内容" v-model="ruleForm.card_item_price">
            <template slot="append">元</template>
          </el-input>
        </el-form-item>
        <el-form-item label="购卡权益：" prop="type">
          <el-radio-group class="rightsRadio" v-model="ruleForm.type">
            <el-radio :label="1">永久有效</el-radio>
            <el-radio :label="2">
              购买后
              <el-input size="small" style="width: 100px" type="number" v-model="ruleForm.dayNum"></el-input>
              天内有效
            </el-radio>
            <el-radio :label="3">
              <el-date-picker size="small" style="width: 200px" v-model="ruleForm.datetime" type="datetime"
                placeholder="选择日期时间"></el-date-picker>
              前有效
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="是否上架：" prop="status">
          <el-radio-group v-model="ruleForm.status">
            <el-radio :label="0">下架</el-radio>
            <el-radio :label="1">上架</el-radio>
          </el-radio-group>
        </el-form-item>
      </div>
      <div v-show="activeTab === 'two'">
        <el-form-item label="" label-width="0px" prop="details">
          <RichText :richTxt="ruleForm.details" @soninfo="val => (ruleForm.details = val)"></RichText>
        </el-form-item>
      </div>
      <div v-show="activeTab === 'three'">
        <el-form-item label="购卡权益：" prop="is_user_right">
          <div>
            <div>
              <el-switch class="mr20" v-model="ruleForm.is_user_right" @change="changeRightsStatus" :active-value="1"
                :inactive-value="0"></el-switch>
              <span class="tips">用户权限不开启，默认为全部用户都执行基础信息内设置的卡项权益；用户权限开启后，支持不同用户不同购卡权益</span>
            </div>
            <el-table class="rightsTable" v-if="ruleForm.is_user_right" :data="ruleForm.user_right_data"
              :header-cell-style="{ 'background-color': '#F8F9FA' }">
              <el-table-column label="用户权限" align="center">
                <template v-slot="{ row }">{{ row.type == 1 ? '创业人员' : row.name + '-' + row.level_id }}</template>
              </el-table-column>
              <el-table-column label="卡项售价" align="center">
                <template v-slot="{ row }">
                  <el-input size="mini" type="number" v-model="row.price"></el-input>
                </template>
              </el-table-column>
              <el-table-column label="推广奖励金额" align="center">
                <template v-slot="{ row }">
                  <el-input size="mini" type="number" v-model="row.award_amount"></el-input>
                </template>
              </el-table-column>
              <el-table-column label="起购数量" align="center">
                <template v-slot="{ row }">
                  <el-input size="mini" type="number" :min="1" v-model="row.min_buy_sum"></el-input>
                </template>
              </el-table-column>
              <el-table-column label="续购数量" align="center">
                <template v-slot="{ row }">
                  <el-input size="mini" type="number" :min="1" v-model="row.continue_buy_sum"></el-input>
                </template>
              </el-table-column>
            </el-table>
            <div class="manyadd" v-if="ruleForm.is_user_right">
              <span>批量添加：</span>
              <el-input placeholder="卡项售价" size="mini" type="number" v-model="manyadd.price"></el-input>
              <el-input placeholder="推广奖励金额" size="mini" type="number" v-model="manyadd.award_amount"></el-input>
              <el-input placeholder="起购数量" size="mini" type="number" v-model="manyadd.min_buy_sum"></el-input>
              <el-input placeholder="续购数量" size="mini" type="number" v-model="manyadd.continue_buy_sum"></el-input>
              <el-button type="primary" @click="sureManyadd" size="mini">确认</el-button>
            </div>
          </div>
        </el-form-item>
      </div>
    </el-form>
    <Preservation v-if="!update_id" @preservation="preservation"></Preservation>

    <!-- 选择服务弹框 -->
    <el-dialog title="选择服务" :visible.sync="chooseServicesFlag" width="800px">
      <div class="chooseServices">
        <el-form class="el-form-search" label-width="100px">
          <el-form-item label="服务名称：">
            <el-input v-model="servicesName" size="small" placeholder="请输入服务名称"></el-input>
          </el-form-item>
          <el-form-item label="服务分类：">
            <el-select size="small" v-more="loadMoreType" v-model="classify_id" filterable remote
              :remote-method="getServiceClassify" placeholder="请选择" class="classify_select">
              <el-option v-for="item in serviceClassify" :key="item.id" :label="item.classify_name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label=" " label-width="25px">
            <el-button type="primary" size="small" @click="getServiceList(1)">搜索</el-button>
          </el-form-item>
        </el-form>
        <!-- 渲染表格 -->
        <el-table :data="serviceList" :header-cell-style="{ 'background-color': '#F8F9FA' }">
          <template slot="empty">
            <No />
          </template>
          <el-table-column label="服务信息" align="center">
            <template v-slot="{ row }">
              <div class="serviceInfo">
                <el-image style="width: 60px; height: 60px" :src="row.cover_picture"></el-image>
                <div class="showServiceName">{{ row.service_name }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="售价" align="center">
            <template v-slot="{ row }">￥{{ row.service_price }}</template>
          </el-table-column>
          <el-table-column label="操作" fixed="right" align="center">
            <template v-slot="{ row }">
              <el-button type="text" @click="chooseThat(row)">选择</el-button>
            </template>
          </el-table-column>
        </el-table>
        <Paging :total="serviceTotal" :page="servicePage" :pageNum="serviceRows" @updatePageNum="updateServiceData">
        </Paging>
      </div>
    </el-dialog>
  </el-main>
</template>

<script>
import Preservation from '@/components/preservation';
import ReadyUploadSource from '@/components/readyUploadSource';
import Paging from '@/components/paging';
import _ from 'lodash';
import RichText from '@/components/richText';
export default {
  components: {
    Preservation,
    ReadyUploadSource,
    Paging,
    RichText,
  },
  data () {
    function checkPicture (rule, value, callback) {
      if (!value) {
        callback(new Error('请上传卡项图片'));
      } else {
        callback();
      }
    }
    function checkServiceItem (rule, value, callback) {
      if (!value.length) {
        callback(new Error('请选择卡项服务'));
      } else {
        callback();
      }
    }
    function checkRights (that, callback) {
      if (that.ruleForm.is_user_right) {
        let result = true;
        for (let i = 0; i < that.ruleForm.user_right_data.length; i++) {
          for (const key in that.ruleForm.user_right_data[i]) {
            if (!that.ruleForm.user_right_data[i][key]) {
              result = false;
              break;
            }
          }
          if (!result) {
            break;
          }
        }
        if (result) {
          callback();
        } else {
          callback(new Error('请将用户权限填写完整'));
        }
      } else {
        callback();
      }
    }
    let that = this;
    return {
      update_id: null,
      activeTab: 'one',
      tabsList: [
        { name: '基础信息', value: 'one' },
        { name: '卡项详情', value: 'two' },
        { name: '用户权限', value: 'three' },
      ],
      ruleForm: {
        card_item_name: '',
        sort: '',
        card_item_picture: '',
        serviceItem: [],
        card_item_price: '',
        type: 1,
        dayNum: 1,
        datetime: null,
        status: 1,
        details: '',
        is_user_right: 0,
        user_right_data: [],
      },
      rules: {
        card_item_name: [{ required: true, message: '卡项名称不能为空', trigger: 'blur' }],
        card_item_picture: [{ required: true, validator: checkPicture, trigger: 'change' }],
        serviceItem: [{ required: true, validator: checkServiceItem, trigger: 'change' }],
        card_item_price: [{ required: true, message: '请填写卡项售价', trigger: 'blur' }],
        status: [{ required: true, trigger: 'change' }],
        type: [{ required: true, trigger: 'change' }],
        details: [{ required: true, message: '请填写卡项详情', trigger: 'blur' }],
        is_user_right: [{ validator: (rule, value, callback) => checkRights(that, callback), trigger: 'change' }],
      },
      // 选择服务弹框的数据
      chooseServicesFlag: false,
      servicesName: '',
      classify_id: 0,
      // 搜索时使用的分类列表
      serviceClassify: [],
      serviceClassifyName: '',
      serviceClassifyPage: 1,
      serviceClassifyRows: 10,
      serviceClassifyTotal: 0,
      serviceList: [],
      servicePage: 1,
      serviceRows: 10,
      serviceTotal: 0,
      // 批量添加
      manyadd: {
        price: '',
        award_amount: '',
        min_buy_sum: '',
        continue_buy_sum: '',
      },
    };
  },
  // 自定义指令
  directives: {
    more: {
      bind (el, binding) {
        const SELECTWRAP_DOM = el.querySelector('.classify_select .el-scrollbar .el-select-dropdown__wrap');
        SELECTWRAP_DOM.addEventListener('scroll', function () {
          const condition = this.scrollHeight - this.scrollTop <= this.clientHeight;
          if (condition) {
            binding.value();
          }
        });
      },
    },
  },
  created () {
    if (this.$route.query.id) {
      this.update_id = this.$route.query.id;
      this.getCardInfo();
    }
  },
  methods: {
    // 服务分类下拉框 滑动加载更多数据
    loadMoreType () {
      if (Math.ceil(this.serviceClassifyTotal / this.serviceClassifyRows) > this.serviceClassifyPage) {
        this.serviceClassifyPage++;
        this.getServiceClassify(this.serviceClassifyName, 1);
      }
    },
    // 获取服务分类
    getServiceClassify (name, is_more) {
      if (!is_more) {
        this.serviceClassifyPage = 1;
      }
      this.serviceClassifyName = name;
      this.$axios
        .post(this.$api.beauty.service.serviceClassifyList, {
          page: this.serviceClassifyPage,
          rows: this.serviceClassifyRows,
          classify_name: name,
        })
        .then(res => {
          if (res.code === 0) {
            if (is_more) {
              this.serviceClassify = this.serviceClassify.concat(res.result.list);
            } else {
              this.serviceClassify = res.result.list;
              this.serviceClassify.unshift({ classify_name: '全部', id: 0 });
            }
            this.serviceClassifyTotal = res.result.total_number;
          }
        });
    },
    // 查询服务列表
    getServiceList (style) {
      if (style) this.servicePage = 1;
      let obj = {
        page: this.servicePage,
        rows: this.serviceRows,
      };
      if (this.servicesName) {
        obj.service_name = this.servicesName;
      }
      if (this.classify_id) {
        obj.classify_id = this.classify_id;
      }
      this.$axios.post(this.$api.beauty.service.serviceList, obj).then(res => {
        if (res.code === 0) {
          this.serviceList = res.result.list;
          this.serviceTotal = res.result.total_number;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 服务列表的分页操作
    updateServiceData (val, status) {
      if (status == 0) {
        this.serviceRows = val;
      } else {
        this.servicePage = val;
      }
      this.getServiceList();
    },
    startChooseService () {
      this.classify_id = 0;
      this.servicesName = '';
      this.chooseServicesFlag = true;
      this.getServiceClassify();
      this.getServiceList(1);
    },
    chooseThat (data) {
      let obj = _.cloneDeep(data);
      this.$set(obj, 'usable_sum', 1);
      this.$set(this.ruleForm.serviceItem, 0, obj);
      this.chooseServicesFlag = false;
    },
    // 点击保存按钮
    preservation () {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          console.log('保存成给');
          let url = !this.update_id ? this.$api.beauty.service.addCard : '';
          let obj = _.cloneDeep(this.ruleForm);
          if (obj.type !== 1) obj.indate = this.ruleForm.type === 2 ? this.ruleForm.dayNum : Math.ceil(this.ruleForm.datetime / 1000);
          obj.service_id = this.ruleForm.serviceItem[0].id;
          obj.usable_sum = this.ruleForm.serviceItem[0].usable_sum;
          delete obj.dayNum;
          delete obj.datetime;
          delete obj.serviceItem;
          this.$axios.post(url, obj).then(res => {
            if (res.code === 0) {
              let str = !this.update_id ? '添加成功' : '保存成功';
              this.$message.success(str);
              this.$router.push('/service/beautyCardService');
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      });
    },
    // 查看详情时获取数据
    getCardInfo () {
      this.$axios
        .post(this.$api.beauty.service.cardInfo, {
          id: this.update_id,
        })
        .then(res => {
          if (res.code === 0) {
            this.ruleForm = res.result
            res.result.service.usable_sum = res.result.usable_sum
            this.$set(this.ruleForm, 'serviceItem', [])
            this.ruleForm.serviceItem.push(res.result.service)
            this.ruleForm.user_right_data = JSON.parse(res.result.user_right_data)
            console.log(res.result);
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    // 改变权益状态
    changeRightsStatus () {
      if (this.ruleForm.is_user_right && !this.ruleForm.user_right_data.length) {
        this.ruleForm.user_right_data.push({
          type: 1,
          price: '',
          award_amount: '',
          min_buy_sum: '',
          continue_buy_sum: '',
        });
        this.getDistributionLevel();
      }
    },
    // 获取分销等级列表并且组装权益数据
    getDistributionLevel () {
      this.$axios.post(this.$api.beauty.service.getLevel).then(res => {
        if (res.code === 0) {
          res.result.map(item => {
            let obj = {
              name: item.name,
              level_id: item.level_id,
              type: 2,
              price: '',
              award_amount: '',
              min_buy_sum: '',
              continue_buy_sum: '',
            };
            this.ruleForm.user_right_data.push(obj);
          });
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 确认批量添加
    sureManyadd () {
      this.ruleForm.user_right_data.map(item => {
        for (const key in this.manyadd) {
          if (this.manyadd[key]) item[key] = this.manyadd[key];
        }
      });
      this.manyadd = {
        price: '',
        award_amount: '',
        min_buy_sum: '',
        continue_buy_sum: '',
      };
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  min-height: 100%;
  background-color: #fff;
  padding-bottom: 80px;

  .el-select,
  .el-input {
    width: 400px;
    flex-shrink: 0;
  }

  .tips {
    font-size: 14px;
    color: #999;
    white-space: nowrap;
  }

  .mr20 {
    margin-right: 20px;
  }

  .serviceItem {
    margin-top: 10px;
    width: 700px;
  }

  .rightsRadio {
    display: flex;
    flex-direction: column;

    .el-radio {
      margin-top: 10px;
    }
  }

  .chooseServices {

    .el-input,
    .el-select {
      width: 200px;

      /deep/ .el-input {
        width: 100%;
      }
    }

    .serviceInfo {
      display: flex;
      align-items: center;

      .showServiceName {
        flex: 1;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        margin-left: 10px;
      }
    }
  }

  .rightsTable {
    width: 800px;
    margin-top: 20px;

    .el-input {
      width: 100%;
    }
  }

  .manyadd {
    margin-top: 20px;

    .el-input {
      width: 140px;
      margin-left: 20px;
    }

    .el-button {
      margin-left: 20px;
    }
  }
}
</style>
